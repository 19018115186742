import React, { FunctionComponent } from 'react';
import LayoutWithProtection from '../components/layout/layout-with-protection';
import State from '../components/state';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const PageNotFoundStateContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NotFound: FunctionComponent = () => {
    const image = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "404.png" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <LayoutWithProtection>
            <PageNotFoundStateContainer>
                <State
                    messageTitle="Nie znaleziono strony!"
                    imageAlt="404"
                    imageFluid={image.file.childImageSharp.fluid}
                />
            </PageNotFoundStateContainer>
        </LayoutWithProtection>
    );
};

export default NotFound;
